import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'events',
  formId: 'DK%20Event%20Planning',
  title: 'Events',
  subTitle: 'Lidt fest går man vel aldrig galt i byen med',
  icon: '/icons/events.svg',
  image: '/assets/images/sunrise/events_supermercat.png',
  theme: 'sunrise-events',
  sections: [
    {
      sectionId: 'hero',
      label: 'Events',
      title: 'Lidt fest går man vel aldrig galt i byen med',
      description:
        'Man behøver ikke at være BFF med alle for at have et godt arbejdsmiljø, men friske fredagsbarer, sjove sommerfester  og jublende julefrokoster løfter stemningen, og måske man får en ven-lega?',
      image: '/assets/images/sunrise/events_supermercat.png',
      icon: '/icons/events.svg',
      buttonText: 'Få et tilbud',
    },
    {
      sectionId: 'options',
      title: 'Alt er sjovere når man er sammen!',
      description:
        'Vi har prøvet det hele - fra månedlige fredagsbarer til årlige julefrokoster. Spar tid og lad os tage os af alt det grove, så du kan slappe af og feste uden at fise rundt som en skoldet skid.',
      optionsItems: [
        {
          icon: '/assets/images/services/events/service_1.svg',
          title: 'Fredagsbar',
          description:
            'Dit ønske er vor lov. Lille, lækker eller laber? Carlsberg eller cocktails? Dinner eller DJ? Alt er muligt.',
        },
        {
          icon: '/assets/images/services/events/service_2.svg',
          title: 'Mærkedage',
          description:
            'Jubilæer, fødselsdage, babyshowers og alt derimellem. Vi kan det. Vis kollegaerne din påskønnelse.',
        },
        {
          icon: '/assets/images/services/events/service_3.svg',
          title: 'Sæsonfester',
          description:
            'Sommerfest, julefrokost, Halloweenfest? Vi kan alle de der særlige ting der skal til for at ramme stemningen.',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'Hvordan virker det?',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/events/journey_1.svg',
          title: 'Send os dit ønske',
          description: 'Fortæl os, hvad der vil gøre netop din fest magisk.',
        },
        {
          icon: '/assets/images/services/events/journey_2.svg',
          title: 'Få skræddersyet forslag',
          description: 'Sammen designer vi den perfekte event.',
        },
        {
          icon: '/assets/images/services/events/journey_3.svg',
          title: 'Modtag et tilbud',
          description: 'Vi sender dig et udspecificeret tilbud med produkter og priser.',
        },
        {
          icon: '/assets/images/services/events/journey_4.svg',
          title: 'Have a blast!',
          description: 'Fest løs! Slap af og nyd festen.',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Hvorfor skal Good Monday stå for jeres events?',
      description: '',
      image: '/assets/images/services/events/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-clock-outline',
          title: 'Spar en masse tid',
          description: 'Vi tager os af det hele. Du skal bare slappe af.',
        },
        {
          icon: 'eva eva-bulb-outline',
          title: 'Mere end du kan forestille dig',
          description: 'Vi har allerede prøvet det hele og har et kæmpe netværk af seje leverandører.',
        },
        {
          icon: 'eva eva-options-2-outline',
          title: 'Skræddersyet til dine behov',
          description:
            'Ikke to events er ens, så vi sikrer at I får en oplevelse der er skræddersyet til jeres ønsker.',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Vidste du at du hos Good Monday kan abonnere på en månedlig fredagsbar?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Få et tilbud',
    },
  ],
}
